/* :root {
  --main-lightyellow:  rgb(250, 233, 140);
  --main-yellow: rgb(255, 250, 104);  
  --main-darkyellow: rgb(136, 126, 73);

  --main-pink: rgb(255,102,102);
  --main-lightpink: rgb(226,138,126);
  --red: red;

  --main-padding: 15px;  
} */
@import '../../utilities/variable.scss';

.Home{
    height: 100%; 

    
}

/* images */
.Home .images{
  display: flex;
  justify-content: space-around;

}

.Home .images img{
  height: 280px;
  width: 48%;
  object-fit: cover;
  margin: 75px 0 50px;

  border-top: 4px solid var(--red);
  border-bottom: 4px solid var(--red);
}
.Home .images img:first-child{
  margin-right: 4%;
}
.Home .sillouete{
  width: 325px;
  border-radius: 90%;
  margin: 75px auto;
  border: 15px solid var(--red);
  box-shadow: 0px 10px 8px var(--red);
}

/* buttons */
.Home .buttons, .Home .buttons-bottom{
  margin: 40px auto 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.Home .buttons button:first-child{
  margin-right: 190px;
}
.Home .buttons button, .Home .buttons-bottom button{
  width:410px
}

/* text */
.Home h1{
  font-size: 3.6rem;
  text-shadow: -6px 0px 0px var(--red);
  padding: 10px 30px;
  color:white;
}
.Home h1 span{
  display: block;
}
.Home h2{
  font-size: 2.4rem;
  color: white;
  margin-bottom: 30px;
  margin-top: 1px;
  padding-top:20px;
  border-top: 2px solid var(--red-dark);
  padding-right: 5px;
     padding-left:5px;
}

.science-paragraph{
  line-height: 1.7rem;
  margin: 35px 35px 0;
  white-space: pre-wrap;
  font-size: 1.1rem;
}
// Media query phone 
//@at-root
@media (max-width: 1100px){
  .Home .buttons button:first-child{
    margin-right: 0px;
  }
}
@media (max-width: $phone){
  .Home{
    h1{
      font-size: 3rem;
    }

    .images{
      justify-content: center;
      flex-wrap: wrap;
      img{
        height: 170px;
        margin: 20px 0 90px;
        width: 96%;
        
      }
      img:first-child{
        margin-right: 0;
        margin-bottom: 10px;
      }
      
    }
    .buttons-bottom button{
      width:90%;
    }
    .buttons{
      button{
        width:90%;
        padding-right:3px;
        padding-left:3px;
      }
      button:first-child{
        margin-right: 0;
        margin-bottom: 60px;
      }
    }

    .sillouete{
      // height: 325px;
      max-width: 96%;

      border-radius: 90%;
      margin: 75px auto;
      border: 15px solid var(--red);
      box-shadow: 0px 3px 8px white;
    }
  }
}

@keyframes rotateAnimation {
  0% {
    offset-distance: 0%;
    transform: rotate(0);
  }
  2% {
    offset-distance: 0%;
    transform: rotate(0);
  }
  98% {
    offset-distance: 100%;
    transform: rotate(360deg) ;
  }
  100% {
    offset-distance: 100%;
    transform: rotate(360deg) ;
  }
}
@keyframes dnaAnimation {
  from {
    // transform: rotate(0deg);
    transform: scale(0.95,1)  rotate(-5deg);
  }
  to {
    // transform: rotate(30deg);
    transform: scale(1,1) rotate(5deg);
  }
}
@keyframes beakerAnimation {
  from {
    transform: scale(1,0.95)  rotate(5deg);
  }
  to {
    transform: scale(1,1) rotate(-5deg);
  }
}

.rollingContainer {
  position: relative;
  width: 90%;
  height: 200px; /* Adjust height as needed */
  overflow: visible;
  // min-width: 580px;
  margin: 0 auto;
}

#softballGroup2 {
  animation: rotateAnimation 4.5s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
  animation-direction: alternate;
  position: absolute;
  top: 0;
  left: 0;
  offset-path: path('M-200,200 L990,200');
  // offset-path: path('m 202.12,52.491225 c 3.31251,-3.67658 9.10988,-1.554326 11.56362,1.965794 4.25681,6.106786 0.90896,14.34608 -5.12478,17.779745 -8.73843,4.972849 -19.65657,0.279213 -23.99586,-8.283763 -5.74075,-11.328536 0.34175,-24.995957 11.44274,-30.211989 13.90291,-6.532567 30.34978,0.957749 36.42811,14.601731 7.33722,16.469791 -1.57076,35.711912 -17.76071,42.644233 -19.03258,8.149499 -41.07928,-2.181898 -48.86036,-20.919698 -8.96668,-21.592884 2.79176,-46.45016 24.07869,-55.076478 24.15158,-9.7871929 51.82351,3.400736 61.2926,27.237666 10.61006,26.709172 -4.00907,57.198683 -30.39665,67.508724 -29.26598,11.43467 -62.57522,-4.61692 -73.72485,-33.555636 -12.26059,-31.822194 5.2244,-67.9527938 36.71462,-79.9409665 34.37797,-13.0875275 73.33121,5.8315987 86.15709,39.8736025 13.91526,36.933404 -6.43857,78.71028 -43.03259,92.37321 -39.48856,14.74365 -84.0899,-7.04535 -98.58933,-46.191569 -10.35797,-27.964909 -3.87292,-60.061453 15.98043,-82.20198927');
  // offset-path: path("M768.500,470.000 L768.500,287.000C768.500,259.386 746.114,237.000 718.500,237.000L425.500,237.000L54.000,237.000 C26.386,237.000 4.000,214.614 4.000,187.000 L4.000,4.000");
}

.beakerContainer{
  animation: beakerAnimation 1.2s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
  animation-direction: alternate;
}
.dnaContainer{
  animation: dnaAnimation 1.2s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
  animation-direction: alternate;
}
.svgBar{
  display:flex;
  position: relative;
  flex-wrap: wrap;
  padding:15px;
  justify-content: center;
}
.svgContainer{
  max-height: 110px;
}
#announcementDiv{
  aspect-ratio: 2/1;
  overflow: hidden;
  width:700px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 30px;
 }
 #announcementDiv iframe{
  margin: 0 auto;
   margin-top:-100px;
   width: 100%;
   height:calc(100% + 150px);
 }
@media only screen and (max-width: 700px) {
  #softballGroup2{
    offset-path: path('M265,200 L590,200');
  }
}
@media only screen and (max-width: 780px) {
  #announcementDiv{
    aspect-ratio: 2/1;
    overflow: hidden;
    width:100%;
   }
   #announcementDiv iframe{
    margin: 0 auto;
     margin-top:-15%;
     width: 100%;
     height:calc(150%);
   }
}

// 1301px 
// 600px 
#associateSection, #associateSection2{
  display:flex;
  flex-direction: column;
  position:absolute;
  top:220px;
  right:10px;
  background-color: var(--black-transparent);
  padding:10px;
  border-radius: 15px;
}
#associateSection2{
  top:20px;
}
#associateLabel span{
  display: block;
  font-size: .8rem;
}
#assocateLinks{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:20px;
}
#assocateLinks span{
  margin-bottom:12px;
  height:60px;
  width:60px;
  border-radius: 100%;
  display:block;
  transition: all 0.3s;
}
#associateLabel2 span{
  display: block;
  font-size: .8rem;
}
#assocateLinks2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:20px;
}
#assocateLinks2 span{
  margin-bottom:12px;
  aspect-ratio: 76/60;
  height:105px;
  width:80px;
  border-radius: 3px;
  display:block;
  transition: all 0.3s;
}
#assocateLinks2 span, #assocateLinks span{
  cursor: pointer;
}
@media only screen and (max-width: 780px) {
  .imgList{
    margin-top:40px;
  }
  #associateSection{
    top:-95px;
    right:100px;
    // background-color: transparent;
    padding-bottom: 5px;
  }
  #associateLabel span{
    font-size: .6rem;
  }
  #assocateLinks{
    margin-top:10px;
  }
  #assocateLinks span{
    // height:40px;
    // width:40px;
    margin-bottom: 0;
  }
  #associateSection2{
    top:-95px;
    right:180px;
    // background-color: transparent;
    padding-bottom: 5px;
  }
  #associateLabel2 span{
    font-size: .6rem;
  }
  #assocateLinks2{
    margin-top:10px;
  }
  #assocateLinks2 span{
    height:76px;
    width:60px;
    margin-bottom: 0;
  }
}
#associateBringIt{
  background-color:whitesmoke;
  background-image: url('../../images/bring-it.jpg');
  background-size: contain;
  padding-bottom: 5px;
}
#associateChampion{
  background-color:whitesmoke;
  background-image: url('../../images/champion.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 5px;
}
#sponsoredBook{
  background-image: url('../../images/bookCover.jpg');
  background-size: cover;
}
#assocateLinks span:hover, #assocateLinks2 span:hover{
  box-shadow: 0 0 20px 0px grey;
  cursor: pointer;
}

#ssLogoDiv{
  background-image: url('../../images/ssBackgorund.png');
  height:300px;
  width: 300px;
  background-size:     500px;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    border-radius: 100%;
    margin:-10px auto 0px;
    
}
#ssBookCover{
  background-image: url('../../images/bookCover.jpg');
  aspect-ratio: 1000/1293;
  width: 300px;
  background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    border-radius: 3px;
    margin:30px auto;
    
}