@import "../../utilities/variable.scss";

.Coaches{
    /* background-image: url('../../images/fence-texture-1.png') */
    background-image: url('../../images/fence-texture-1.png');
}
.Coaches h1{
    font-size: 1.5rem;
    padding: 10px 0 5px;
    text-shadow: -3px -2px 0px black;
}
.Coaches h2{
    font-size: 30px;

    border-bottom: 4px solid rgba(54,0,0, .8);
    margin: 20px 0 0px;
    width: auto;
    padding-bottom: 25px;
     padding-right: 5px;
     padding-left:5px;

}


.Coaches .coach-container {
    display:flex;

    justify-content: center;
    flex-wrap: wrap;

}
/* 
Coach Cards
========================== */

.Coaches .coach-container .coach-card{
    background: rgba(212, 15, 64, .8);
    width: 420px;
    min-width: 30%;
    display: flex;
    flex-direction: column;
    margin: 20px 2.5%;
    border-radius: 40px;
    
}
.Coaches .coach-container .card-2nd{
    /* (54,153,236) */
    background: rgba(54,163,250, .8);

}

// .Coaches .coach-container .coach-card.deactive{

// }


/* images */
/* ======================================== */
.Coaches .coach-container .coach-card .img-div{
    height: 420px;
    margin: 0px auto 0px;
    display: flex;

}
.Coaches .coach-container .coach-card .img-div .img-overflow{
    width: 100%;
    height: 420px;
    // border-radius: 10px;
    overflow: hidden;
    transition: width .7s ;
    
}
.singleCoachImage{

}
.Coaches .coach-container .coach-card .img-div .active{
    // width: 150px;
    // height: 200px;
}

.Coaches .coach-container .coach-card .img-div img{
    width: 100%;
    // border-radius: 10px;
    opacity: .89;
    margin: 25px 3px 0px;
    transition: margin .4s, opactiy .4s;
}
.Coaches .coach-container .coach-card .img-div img:hover{
    transition: margin .4s, opactiy .4s;
    margin: 0px 3px 0px;
    opacity: 1.0;
}
.Coaches .coach-container .coach-card .img-div .active img{
    margin: 0px 0px 0px;
}
.Coaches .coach-container .coach-card .img-div div:last-child img{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.Coaches .coach-container .coach-card .img-div .img-overflow:hover{
    // width: 150px;a
    // margin: 0 4px 0;
    transition: width .7s;
 

}
.Coaches .coach-container .coach-card .img-div .active img{
    opacity: 1.0;
}
.Coaches .coach-container .coach-card .img-div .active img:hover{
    opacity: 1.0;
}
.Coaches .coach-container .coach-card .img-div .index0{
    order: 2;
}
.Coaches .coach-container .coach-card .img-div .index1{
    order: 1;
}
.Coaches .coach-container .coach-card .img-div .index2{
    order: 3;
}


@media (max-width: $phone){
    .Coaches .coach-container .coach-card .img-div{
        // margin-bottom: 200px;
        flex-wrap: wrap;
        .index0{
            order: 1;
        }
        .index1{
            order: 2;
            display: none;
        }
        .index2{ 
            order: 3;
            display: none;
        }
    }
}

/* buttons */
/* ==================================== */
.Coaches .coach-container .coach-card .button-main{
    // border: 4px solid rgba(54,163,250, 1);
    margin: 15px 0 15px;
    min-width:95%;
    padding-top: 15px;
    padding-bottom:15px;
    // border-radius: 80px;
}
.Coaches .coach-container .coach-card .button-main:hover{
    border: 4px solid rgba(124,193,255, 1);
    cursor: pointer;
}
.Coaches .coach-container .coach-card .button-main::after{
    border: 2px solid #ccc;
}
// .Coaches .coach-container .coach-card .btn-div{
    
// }
.Coaches .coach-container .card-2nd .button-main{
    // border: 4px solid rgba(240, 15, 64, 1);
    // border-radius: 0px;
    // border-bottom-right-radius: 0px;
    // border-bottom-left-radius: 0px;
    // background-color: rgba(124,193,255, 1);
}
.Coaches .coach-container .card-2nd .button-main:hover{
    border: 4px solid rgba(250, 70, 134, 1);
    background-color: #3b3b3b;
    cursor: pointer;
}

.Coaches .end-quote{
    padding-bottom: 75px;
}


/* POP UP STYLES */
/* ================================= */

.popup-main{
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow-x: auto;
    overflow-y: scroll;
    position: fixed;
    z-index: 1000;
    background-image: url('../../images/fence-texture-2.png');
    background-repeat: repeat;
    background-size: 300px 300px;
    display: flex;
    /* display:none; */
}
.popup-main.deactive{
    display: none;
}
.popup-main .popup-window{
    width: 50%;
    min-width: 740px;
    min-height: 150px;
    background-color: rgb(50,50,50);
    margin: auto auto;
    position: relative;
    border: 3px solid var(--red);
    padding-bottom: 45px;
}

/* Media Queries -- phone and tablet styles 
*/

@media (max-width: $phone){
    .popup-main .popup-window{
        width: 94%;
        min-width: 240px;
    }
}

/* close buttons */
/* =================================== */

.popup-main .popup-window .close-x{
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 15px;
    background-color: rgba(0,0,0,.5);
}
.popup-main .popup-window .close-x:hover{
    color: rgb(155,155,155);
    background-color: rgba(0,0,0,.6);
    cursor: pointer;
}

.popup-main .popup-window .close-button{
    position: absolute;
    bottom: 0;
    right: 40%;
    width: 20%;
    padding: 12px 25px;
    background-color: rgba(0,0,0,.5);
}
.popup-main .popup-window .close-button:hover{
    color: rgb(155,155,155);
    background-color: rgba(0,0,0,.6);
    cursor: pointer;
}

/* Popup Content  */
/* ================================= */

.popup-main .popup-window .popup-text{
    font-size: 1.1rem;
    white-space: pre-wrap;
    text-align: left;
    padding: 30px 60px 60px;
    line-height: 1.7rem;
}

.popup-main .popup-window h1{
    font-size: 3.6rem;
    text-shadow: -6px 0px 0px var(--red);
    padding: 10px 30px;
    margin: 30px 0 0;
    color: var(--main-yellow);
}
/* Media Queries -- phone and tablet styles 
*/

@media (max-width: $phone){
    .popup-main .popup-window .close-button{
        right: 33%;
        width: 34%;
    }
    .popup-main .popup-window .popup-text{
        padding: 30px 25px 60px;
    }
    .popup-main .popup-window h1{
        font-size: 3.0rem;
    }
}

